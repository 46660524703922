import './App.css';
import Home from './Components/Home';
import Domains from './Components/Domains';
import Footer from './Components/Footer';


function App() {
  return (
    <div className="App">
      <Home/>
      <Domains/>
      <Footer/>
    </div>
  );
}

export default App;
